<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/zigongsi/zigongsi-ban.png" />
      <div class="tp-title">子公司</div>
      <div class="tp-ic"></div>
      <div class="tp-body">
        <div class="underline"></div>
      </div>
      <div class="tp-content">
        <div class="tp-video">
          <img
            style="width: 900px"
            src="../../assets/images/synopsis/corporation.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}

</script>
<style lang="scss" scoped>
.header-top {
  min-width: 1250px;
  height: 100%;
  margin: auto;

  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }

  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background: #8cc6ee;
  }

  .tp-content {
    width: 865px;
    // text-align: center;
    margin: 20px auto;
  }
}

.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}

.tp-body {
  width: 560px;
  margin: auto;
}

.tp-img {
  height: 380px;
  width: 100%;
}

.tp-video {
  //margin: 50px 0px 50px 620px;
  margin-top: 30px;
  margin-left: -20px;
}
</style>
